<script setup lang="ts">
import type { DirectusUsers } from '@laast-io/types'

const props = defineProps<{
  user?: string | DirectusUsers | null
}>()

const { user: loggedUser } = useDirectusAuth()

const currentUser = computed(() => {
  if (typeof props.user === 'string')
    return null
  if (props.user?.id && props.user.id === loggedUser.value?.id)
    return loggedUser.value
  return props.user
})
const initials = computed(() => {
  if (!currentUser.value)
    return '?'
  return formatInitials(currentUser.value) || '?'
})

const colors = [
  // 'white',
  // 'muted',
  'primary',
  'success',
  'info',
  'warning',
  'danger',
  'pink',
  'yellow',
  'indigo',
  'violet',
] as const

const color = computed(() => {
  let initialsCode = 0

  for (let i = 0; i < 6; i++) {
    initialsCode += currentUser.value?.id?.charCodeAt(i) ?? 0
  }

  return colors[initialsCode % colors.length]
})
</script>

<template>
  <BaseAvatar
    :text="initials"
    :color="color"
  />
</template>
