<script setup lang="ts">
import type { DirectusUsers } from '@laast-io/types'

const props = defineProps<{
  user?: string | DirectusUsers | null
}>()
const attrs = useAttrs()
const avatar = useUserAvatar(() => {
  if (typeof props.user === 'string')
    return null
  return props.user
})

const error = ref(false)

if (import.meta.browser) {
  watchEffect(() => {
    if (!avatar.value)
      return

    const img = new Image()
    img.onload = () => {
      img.remove()
      error.value = false
    }
    img.onerror = () => {
      img.remove()
      error.value = true
    }
    img.src = avatar.value
  })
}
</script>

<template>
  <BaseAvatar
    v-if="avatar && !error"
    :src="avatar"
    v-bind="attrs"
    :title="formatFullname(typeof props.user === 'string' ? null : props.user)"
    @error="error = true"
  />
  <AppAgentPlaceholder
    v-else
    :user="props.user"
    v-bind="attrs"
    :title="formatFullname(typeof props.user === 'string' ? null : props.user)"
  />
</template>
