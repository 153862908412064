import type { DirectusUsers } from '@laast-io/types'

export function useUserAvatar(user: MaybeRefOrGetter<string | DirectusUsers | null | undefined>) {
  const { user: currentUser } = useDirectusAuth()

  return useDirectusAsset(() => {
    const _user = toValue(user)
    if (typeof _user === 'string') {
      if (import.meta.env.DEV) {
        console.warn('useUserAvatar: user is a string, expected DirectusUser')
      }
      return ''
    }
    if (!_user) {
      if (import.meta.env.DEV) {
        console.warn('useUserAvatar: user not set')
      }
      return ''
    }

    if (_user.id === currentUser.value?.id) {
      return currentUser.value?.avatar?.id
    }
    return _user.avatar as string
  })
}
